
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class RadialMobile extends Vue {
  mounted() {
    const owl = $('.owl-carousel');
    owl.on('click', '.owl-dot', function handle() {
      owl.trigger('to.owl.carousel', $(this).index());
    });
  }
}
