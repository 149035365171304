
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";

declare let d3: any;

@Component({})
export default class Radial extends Vue {
  public intervalFunc: any;

  public mouseEnterFunc: any;

  public mouseLeaveFunc: any;

  mounted() {
    const width = window.innerWidth.toString(); // "1024";
    const height = 600;
    const margin = 4;
    // The radius of the pieplot is half the width
    // or half the height (smallest one). I subtract a bit of margin.
    const radius = Math.min(Number(width), height) / 2 - margin;

    const order = (this.$t("technology.options") as any).map(
      (r: any) => r.header
    );
    let lastIndex = -1;
    let lastHovered = null;
    let isPaused = false;

    // append the svg object to the div called 'technology_radar_content'
    const svg = (d3 as any)
      .select("#technology_radar_content")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${Number(width) / 2},${height / 2 - 25})`);

    const data = (this.$t("technology.options") as any).reduce(
      (r: any, g: any) => ({
        ...r,
        [g.header]: 1,
      }),
      {}
    );

    const removeSelection = (r: any) => {
      const re = $(r).text();
      $(r).removeClass("hover");
      $(`path[data-identifier="${re}"]`)
        .attr("stroke", "var(--color-gray)")
        .attr("fill", "var(--color-white)");
      $(".radial-header-description").text("");
    };

    const selectElement = (r: any) => {
      const re = $(r).text();
      $(r).addClass("hover");
      d3.select($(`path[data-identifier="${re}"]`)[0]).raise(); // force blue to top
      $(`path[data-identifier="${re}"]`)
        .attr("stroke", "var(--color-blue)")
        .attr("fill", "var(--color-blue)");
      const selected = (this.$t("technology.options") as any).filter(
        (e: any) => e.header === re
      );
      if (selected.length) {
        $(".radial-header-description").text(selected[0].description);
      }
    };

    // Compute the position of each group on the pie:
    const pie = (d3 as any)
      .pie()
      .sort(null) // Do not sort group by size
      .value((d: any) => d.value);
    const dataReady = pie(d3.entries(data));

    // The arc generator
    const arc = (d3 as any)
      .arc()
      .innerRadius(radius * 0.68) // This is the size of the donut hole
      .outerRadius(radius * 0.72);

    // Another arc that won't be drawn. Just for labels positioning
    const outerArc = (d3 as any)
      .arc()
      .innerRadius(radius * 0.95)
      .outerRadius(radius * 0.95);

    (svg as any)
      .append("foreignObject")
      .attr("y", "-140")
      .attr("x", "-140")
      .attr("width", "280")
      .attr("height", "280")
      .append("xhtml:p")
      .attr("class", "radial-header-description")
      .attr("xmlns", "http://www.w3.org/1999/xhtml")
      .text("");

    // Build the pie chart: Basically, each part of the pie
    // is a path that we build using the arc function.
    (svg as any)
      .selectAll("allSlices")
      .data(dataReady)
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", "var(--color-white)")
      .attr("class", "aophgia08121")
      .attr("data-identifier", (d: any) => d.data.key)
      .attr("stroke", "var(--color-gray)")
      .style("stroke-width", "8px")
      .style("opacity", 1);

    (svg as any)
      .selectAll("allLabels")
      .data(dataReady)
      .enter()
      .append("foreignObject")
      .attr("width", "420")
      .attr("height", "120")
      .attr("x", (d: any) => {
        const pos = outerArc.centroid(d);
        const leftOffset = 125;
        const smallerLeftOffset = 50;
        const centerOfRadial = 0;
        const centerOfRadialRightMargin = 5;

        if (pos[0] < centerOfRadial) {
          return pos[0] - leftOffset;
        } else if (
          pos[0] >= centerOfRadial &&
          pos[0] < centerOfRadialRightMargin
        ) {
          return pos[0] - smallerLeftOffset;
        } else {
          return pos[0];
        }
      })
      .attr("y", (d: any) => {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
        return pos[1] - 20;
      })
      .append("xhtml:div")
      .attr("class", () => "radial-header-content")
      .style("text-anchor", (d: any) => {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? "start" : "end";
      })
      .text((d: any) => d.data.key);

    this.mouseEnterFunc = (r: any) => {
      isPaused = true;
      const current = $(
        `.radial-header-content:contains("${order[lastIndex]}")`
      );
      removeSelection(current);

      const re = $(r.currentTarget).text();
      lastIndex = order.indexOf(re);

      $(r.currentTarget).addClass("hover");
      d3.select($(`path[data-identifier="${re}"]`)[0]).raise(); // force blue to top
      $(`path[data-identifier="${re}"]`)
        .attr("stroke", "var(--color-blue)")
        .attr("fill", "var(--color-blue)");
      const selected = (this.$t("technology.options") as any).filter(
        (e: any) => e.header === re
      );
      if (selected.length) {
        $(".radial-header-description").text(selected[0].description);
      }
    };

    $(".radial-header-content").on("mouseenter", this.mouseEnterFunc);

    this.mouseLeaveFunc = () => {
      isPaused = false;
    };

    $(".radial-header-content").on("mouseleave", this.mouseLeaveFunc);

    const selectNewElement = () => {
      if (isPaused) {
        return;
      }
      const current = $(
        `.radial-header-content:contains("${order[lastIndex]}")`
      );
      removeSelection(current);

      lastIndex = (lastIndex + 1) % order.length;
      lastHovered = $(`.radial-header-content:contains("${order[lastIndex]}")`);
      selectElement(lastHovered);
    };
    selectNewElement();
    this.intervalFunc = setInterval(selectNewElement, 5000);
  }

  destroyed() {
    if (this.intervalFunc) {
      clearInterval(this.intervalFunc);
    }

    if (this.mouseLeaveFunc) {
      $(".radial-header-content").off("mouseleave", this.mouseLeaveFunc);
    }

    if (this.mouseEnterFunc) {
      $(".radial-header-content").off("mouseenter", this.mouseEnterFunc);
    }
  }
}
